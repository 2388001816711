@import url(https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;1,300;1,400&display=swap);
body {
  margin: 0;
  padding:0;
  font-family: 'Open Sans', sans-serif;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'Avenir-Next';
  src: local('Avenir-Next'), url(/static/media/AvenirNextLTPro-Bold.711a4eb6.otf), format('otf');
}
html {
  scroll-behavior: smooth;
  background-color: #1c1c20;
}

.red-font {
  color: #89110b;
}

.loading-component {
  min-width: 100%;
  height: 100px;
}

.center {
  text-align: center;
}

.section-title {
  text-transform: uppercase;
  font-size: 21px;
  font-weight: 600;
}

.authorbox {
  font-size: 0.5em;
  color: white;
  background-color: #1c1c20;
  text-align: right;
  padding: 7px 12%;
  font-style: italic;
}

.strabe {
  color: white;
  font-weight: 600;
}
.strabe:hover {
  color: lightgreen;
  text-decoration: none;
}

.App {
  background: #1c1c20;
  color: white;
  width: 100vw;
}

.Loading-Screen {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading-gif {
  width: 70px;
}

#somos {
  width: 100%;
}
.Header {
  height: 126px;
  background-color: transparent;
  margin-bottom: -126px;
  z-index: 1;
}

.navbar-brand {
  margin: 0 1em;
}

.Header-Logo {
  width: 150px;
}

.menu-items {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 26px;
  overflow: hidden;
  font-size: 10px;
}

.menu-item {
  border-right: 1px solid white;
  text-align: center;
  width: 150px;
  text-transform: uppercase;
  font-weight: 600;
  text-shadow: 0 0 5px rgba(0, 0, 0, 0.6);
}

.last-item {
  border-right: 0px solid transparent;
}

.white-link {
  color: white !important;
}

.white-link:hover {
  opacity: 0.8 !important;
  text-decoration: underline !important;
}

.Slider {
  display: flex;
}

.SliderCol1 {
  width: 18%;
  display: flex;
  flex-direction: column;
  padding-top: 126px;
}

.mar-vol-container {
  min-height: 600px;

  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  /* padding: 40px;
  display:flex;
  flex-direction: column;
  justify-content: space-between; */
}

.mar-vol-text {
  transform: rotate(-90deg);
  letter-spacing: 0.2em;
  text-transform: uppercase;
  font-size: 14px;
  min-width: 300px;
}

.linkedin {
  font-size: 2em;
  color: #717172;
  background-color: transparent;
  border: none;
}

.linkedin:hover {
  color: #c12929;
}

.Slide-info {
  margin-bottom: 40%;
  text-align: left;
  margin-left: 20%;
}

.SliderCol2 {
  width: 82%;
}

.slide {
  width: 100%;
  height: 700px;
  background-position: center;
  background-size: cover;
}

.slide-counter {
  color: red;
  font-size: 1.5em;
  margin: 0.5em;
  padding: 0.5em;
}

/*about us*/

.AboutUs {
  padding: 3em;
  display: flex;
}

.mid {
  width: 50%;
}

.aboutus-col1 {
  padding: 2em;
  margin: 0 4%;
}

.Bullet-container {
  margin: 1em;
}

.about-us-content-container {
  max-width: 84%;
  margin: auto;
}

.about-icon {
  font-size: 2em;
  margin: 0.2em 0.6em;
  color: #94060c;
}

.Abouts-bullets {
  display: flex;
  flex-direction: column;
}

.Aboutus-picture {
  width: 100%;
  max-height: 800px;
}

/* proyectos*/

#esencia{
  min-height:600px;
}

.Proyects {
  min-height:500px;
  display: flex;
  overflow: hidden;
  overflow-x: scroll;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.Proyects::-webkit-scrollbar {
  display: none;
}

.Sections-Intro {
  display: flex;
  flex-direction: column;
  padding: 2em 4% 1em 4%;
  align-items: center;
  justify-content: center;
  width: 54%;
  margin: auto;
}

.project-container {
  min-width: calc(96% / 4);
  min-height: 400px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  transition: all 0.3s;
  overflow: hidden;
}

.project-container:hover {
  transform: scale(1.1);
}

.Project-title-turned {
  transform: rotate(-90deg);
  transform-origin: right bottom;
  text-transform: uppercase;
  padding: 20px 8px;
  font-size: 12px;
}

/*experiencia*/

.our-exp-tabs > .nav-item{
  background-color:transparent;
  color:#94060c;
  font-weight:600;
}

.our-exp-tabs > .nav-item:hover{
  background-color:#94060c;
  color:white;
  border: 1px solid #94060c!important;
}


.years-container2 {
  font-size: 40px;
}

.years-container1 {
  font-weight: 600;
  background-clip: text;
  -webkit-background-clip: text;
  background-size: cover;
  color: transparent;
  font-size: 400px;
  font-weight: 900;
}

.years-experience {
  display: flex;
  justify-content: center;
  width: 45%;
  margin: auto;
  align-items: center;
}

.Experience > nav {
  /* display:none!important; */
  padding: 3em;
  display: flex;
  justify-content: space-around;
}

.Experience > nav > .nav-item {
  /* display:none!important; */
  text-align: center;
}

.our-exp-tabs {
  border: 0 solid transparent !important;
}

/* css para experience aparte */



.client-logo {
  min-width: 350px;
  margin: 24px;
  padding: 24px;
}

/*contacto*/

.contact-form-container {
  display: flex;
  justify-content: center;
}

.Contact {
  background-color: #151518;
  padding: 5% 0;
}

.contact-form {
  display: grid;
  grid-template: repeat(3, 55px) / repeat(3, 300px);
  grid-gap: 0.5em;
  grid-template-areas:
    "telinput phoneinput historyinput"
    "emailinput companyinput historyinput"
    "submitform submitform submitform";
}

.contact-form input {
  background-color: #1c1c20;
  color: white;
  width: 100%;
  height: 100%;
  box-shadow: none;
  border: none;
  border-radius: 8px;
  padding: 0 20px;
}

.telinput {
  grid-area: telinput;
}
.phoneinput {
  grid-area: phoneinput;
}
.emailinput {
  grid-area: emailinput;
}
.companyinput {
  grid-area: companyinput;
}
.historyinput {
  grid-area: historyinput;
}
.submitform {
  grid-area: submitform;
  display: flex;
  justify-content: center;
  align-items: center;
}

.submit-button {
  border-radius: 19px;
  padding: 9px 16px;
  background-color: #94060c;
  color: white;
  box-shadow: none;
  border: none;
  font-weight: 600;
}

.submit-button:hover {
  color: #94060c;
  background-color: white;
}


.Blog{
  margin-top:126px;
  display:flex;
  flex-direction: column;
  overflow: hidden;
  overflow-y: scroll;
}

.Blog-container{
  margin:1em;
  padding:1em 4%;
}


.blog-entry-image{
  width:100%;
}


.Blog-Entry{
  display:grid;
  grid-template: 1fr / 1fr 1fr;
}

.Entry-Featured-Text{
  margin:44px 0;
}


.Blog-Entry-Container{
  flex-basis: 1fr;
  align-self: center;
}

.Blog-Entry-Text-container{
  padding:3%;
}

.View-Entry-Button-Container{
  text-align: center;
}


.Entry-Head{
  background-size: cover;
  background-repeat: no-repeat;
  height:400px;
  background-position: center;
}


.Entry-Content-inner{
  text-align: justify;
}

.Entry-Content{
  width:60%;
  margin:auto;
  padding: 45px 0;
  
}


.Entry-Content-Title{
  text-align: center;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 2em;
  margin:12px;
}


.Entry-Content-Featured-Text{
  font-size:1.35em;
  color:#94060c;
  font-weight: 600;
  margin:40px 0;
  line-height: 1.2em;
  text-align: justify;
}


/*Footer*/

.Footer {
  display: flex;
  justify-content: space-between;
}

.footer-logo-conainer {
  text-align: center;
}
.Footer-Logo {
  width: 36%;
}

.Footer-box1-container {
  display: flex;
  flex-direction: column;
  color: #94060c;
  border-left: 1px solid white;
  margin: 1em 55px;
  padding: 0px 2em;
  font-weight: 600;
}

.copyright {
  font-size: 7.5px;
  text-align: center;
}

.testing-gap {
  min-height: 600px;
}

#lightbox {
  z-index: 99;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  /*optional bg color*/
  background-color: rgba(0, 0, 0, 0.7);
  /*the following isn't necessary but will help center everything once we add buttons*/
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 1em;
}

.lightbox-active-slide-container {
  width: 600px;
  height: 400px;
  overflow: hidden;
}

.lightbox-active-picture {
  width: 100%;
}

.slider-arrow{
  font-size:2em;
  color:white;
  background-color: transparent;
  border:none;
  z-index:20000;
}

.slider-arrow:hover{
  opacity:0.5;
  cursor:pointer;
}

.exit-lightbox {
  position: absolute;
  top: 33px;
  right: 29px;
  cursor: pointer;
  font-size:2em;
  color:white;
}

.exit-lightbox:hover{
  opacity:0.5;
}


@media screen and (max-width: 768px) {
  .Header {
    display: none !important;
  }

  .AboutUs {
    padding: 0;
    flex-direction: column-reverse;
  }

  .Aboutus-picture-container {
    width: 90%;
    margin: 45px auto;
  }

  .mid {
    width: 100%;
  }

  .aboutus-col1 {
    padding: 0;
    margin: 0;
  }

  .slider-prev {
    position: fixed;
    bottom: 10px;
    left: 10px;
  }

  .slider-next {
    position: fixed;
    bottom: 10px;
    right: 10px;
  }

  .Sections-Intro {
    width: 90%;
    padding: 45px 0;
  }

  .project-container {
    min-width: 90%;
    min-height: 500px;
  }

  .project-container:hover {
    cursor: pointer!important;
  }

  .years-experience {
    flex-direction: column;
    font-size: 6em;
    width: 100%;
  }

  .years-container1 {
    font-size: 2.5em;
  }

  .years-container2 {
    text-align: center;
  }

  .contact-form {
    display: flex;
    min-width: 279px;
    height: 484px;
    flex-direction: column;
    justify-content: space-evenly;
    font-size: 10px;
  }

  .contact-form input {
    min-height: 40px;
  }

  .historyinput {
    height: 100px;
  }

  .Footer {
    flex-direction: column;
  }

  .footer-logo-conainer {
    width: 99%;
  }

  .About-us-row-bullet-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  

  .client-logo{
    width:200px;
  }

  

  .Blog-Entry{
    display:grid;
    grid-template: 1fr 1fr /  1fr;
  }


  .light-box-imagescontainer{
    margin-top:200px;
  }

  .exit-lightbox{
    top: 161px;
  }

  .Blog{
    margin-top:0;
  }

  .Blog-Entry{
    display: flex;
    flex-direction: column;
  }

  .Entry-Content{
    width: 100%;
    padding: 10px 25px 25px 10px;
  }
  .Entry-Content-inner > h2{
    margin:20px 0 20px 0;
  }

  .Entry-Content-inner > h3{
    margin:20px 0 20px 0;
  }
}

@media screen and (min-width: 768px) {
  .MobileMenu {
    display: none!important;
  }
}


.MobileMenu{
  background: #1c1c20;
  color:turquoise!important;
}

.mobile-logo{
  width:100%;
}

.mobile-logo-container{
  width:50px;
}
.Logos-container{
  width:100%;
  overflow: hidden;
  overflow-x: scroll;

  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.Logos-container::-webkit-scrollbar {
  display: none;
}

.Logos2-container::-webkit-scrollbar {
  display: none;
}

.Logos-container-scrollable{
  display:flex;
  max-height:400px;
  align-items: center;
  justify-content: flex-start;
  padding:1em;
  width:auto;
}


.Logos-container-scrollable{
  animation-duration: 33s;
  animation-name: animateclientes;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  position:inherit;
  animation-timing-function: ease-in-out;
}


.Logos2-container-scrollable{
  display:flex;
  max-height:400px;
  align-items: center;
  justify-content: flex-start;
  padding:1em;
  width:auto;
}


.Logos2-container-scrollable{
  animation-duration: 10s;
  animation-name: animatesocios;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  position:inherit;
  animation-timing-function: ease-in-out;
}

@keyframes animateclientes {
  0% {
   transform: translateX(10%);
  }
  100% {
    transform: translateX(-540%);
  }
}

@keyframes animatesocios {
  0% {
   transform: translateX(10%);
  }
  100% {
    transform: translateX(-10%);
  }
}
