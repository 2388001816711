.Logos-container{
  width:100%;
  overflow: hidden;
  overflow-x: scroll;

  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.Logos-container::-webkit-scrollbar {
  display: none;
}

.Logos2-container::-webkit-scrollbar {
  display: none;
}

.Logos-container-scrollable{
  display:flex;
  max-height:400px;
  align-items: center;
  justify-content: flex-start;
  padding:1em;
  width:auto;
}


.Logos-container-scrollable{
  animation-duration: 33s;
  animation-name: animateclientes;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  position:inherit;
  animation-timing-function: ease-in-out;
}


.Logos2-container-scrollable{
  display:flex;
  max-height:400px;
  align-items: center;
  justify-content: flex-start;
  padding:1em;
  width:auto;
}


.Logos2-container-scrollable{
  animation-duration: 10s;
  animation-name: animatesocios;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  position:inherit;
  animation-timing-function: ease-in-out;
}

@keyframes animateclientes {
  0% {
   transform: translateX(10%);
  }
  100% {
    transform: translateX(-540%);
  }
}

@keyframes animatesocios {
  0% {
   transform: translateX(10%);
  }
  100% {
    transform: translateX(-10%);
  }
}