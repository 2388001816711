@media screen and (min-width: 768px) {
  .MobileMenu {
    display: none!important;
  }
}


.MobileMenu{
  background: #1c1c20;
  color:turquoise!important;
}

.mobile-logo{
  width:100%;
}

.mobile-logo-container{
  width:50px;
}